import React, { useState, useContext, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import  { buyNftCard } from '../../blockchain/nft';

import { ToastContext } from '../../context/toast';

const NftBuyModal = ({ nftSaleState, isNftBuyModalActive, onNftBuyModalClose }) => {
  const { addToast } = useContext(ToastContext);

  const [pendingTx, setPendingTx] = useState(false);
  const [val, setVal] = useState('');

  const setValue = (newValue) => {
    setVal(newValue);
  }

  const onValChange = (evt) => {
    const { target } = evt;
    const newValue = parseInt(target.value);
    setVal(isNaN(newValue) ? 0 : newValue);
  }

  const max = useMemo(() => {
    return Math.min(
      new BigNumber(nftSaleState.nftCardsRemaining).toNumber(),
      new BigNumber(nftSaleState.nftCardPerAccountMaxTotal).minus(nftSaleState.userNftCardTotally).toNumber(),
      new BigNumber(nftSaleState.userBalance).div(nftSaleState.salePrice).toNumber()
    );
  }, [
    nftSaleState.nftCardsRemaining,
    nftSaleState.nftCardPerAccountMaxTotal,
    nftSaleState.userNftCardTotally,
    nftSaleState.userBalance,
    nftSaleState.salePrice,
  ]);

  const renderValues = () => {
    const possibleValues = [];

    for (let i = 0; new BigNumber(nftSaleState.nftCardPerAccountMaxTotal).gt(i); i++) {
      possibleValues.push(i + 1);
    }

    return possibleValues.map(value => (
      <button
        key={ `value-${value}` }
        disabled={ value > max }
        className="button is-success is-small is-outlined"
        onClick={ () => setValue(`${value}`) }
      >
        { value }
      </button>
    ))
  }

  const canBuy = () => {
    const value = new BigNumber(val);
    return !value.isNaN() && value.gt(0) && value.lte(max);
  }

  const renderPrice = () => {
    if (canBuy()) {
      return new BigNumber(nftSaleState.salePrice).div(new BigNumber(10).pow(nftSaleState.tokenDecimals)).times(val).toFixed();
    }

    return 0;
  }

  const handleNftBuy = async () => {
    const amount = new BigNumber(val).toString();

    let tx;
    try {
      setPendingTx(true);
      tx = await buyNftCard(amount, nftSaleState.current);
      await tx.wait();
      addToast('NFTs Sale purchase succeeded', 'is-success');
      setPendingTx(false);
      setVal('0');
      onNftBuyModalClose();
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if(tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('NFTs Sale purchase failed', 'is-danger');
      setPendingTx(false);
    }
  }

  return (
    <div className={`modal ${isNftBuyModalActive ? 'is-active' : null}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Buy NFT</p>
          <button className="delete" aria-label="close" onClick={ onNftBuyModalClose }></button>
        </header>
        <section className="modal-card-body">
          <div className="buttons is-right mb-0">
            { renderValues() }
          </div>
          <div className="field has-addons">
            <div className="control is-expanded">
              <input className="input has-text-right" type="text" placeholder="0" value={ val } onChange={ onValChange } />
            </div>
            <div className="control">
              <button type="button" className="button is-static">
                NFTs
              </button>
            </div>
          </div>
          <div className="level is-mobile mb-1">
            <div className="level-left">
              <div className="level-item">
                Your USDC balance:
              </div>
            </div>
            <div className="level-left">
              <div className="level-item">
                <h6 className="has-text-weight-semibold is-size-6">${ new BigNumber(nftSaleState.userBalance).div(new BigNumber(10).pow(nftSaleState.tokenDecimals)).toFormat(3) }</h6>
              </div>
            </div>
          </div>
          <div className="level is-mobile mb-1">
            <div className="level-left">
              <div className="level-item">
                Price per NFT:
              </div>
            </div>
            <div className="level-left">
              <div className="level-item">
                <h6 className="has-text-weight-semibold is-size-6">${ new BigNumber(nftSaleState.salePrice).div(new BigNumber(10).pow(nftSaleState.tokenDecimals)).toFormat(0) }</h6>
              </div>
            </div>
          </div>
          <div className="level is-mobile mb-1">
            <div className="level-left">
              <div className="level-item">
                USDC spent:
              </div>
            </div>
            <div className="level-left">
              <div className="level-item">
                <h6 className="has-text-weight-semibold has-text-primary is-size-6">${ renderPrice() }</h6>
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot is-justify-content-flex-end">
          <button
            onClick={ handleNftBuy }
            disabled={ pendingTx || !canBuy() }
            className={ `button is-success ${pendingTx ? 'is-loading' : ''}` }
          >
            Confirm
          </button>
          <button
            disabled={ pendingTx }
            className="button"
            onClick={ onNftBuyModalClose }
          >
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
}

export default NftBuyModal;
