import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import multicall from './multicall';
import erc20Abi from '../config/abis/erc20.json';
import poolsV2Abi from '../config/abis/poolsV2.json';
import { getAddress, getLpAddress } from '../utils/commons';
import { getSignedContract, getWalletAddress } from './commons';

import poolInitialState from '../state/poolInitialState';
import { getNfts } from './nft';

const ZERO = new BigNumber(0);

export const fetchPools = async () => {
  let erc20Calls = [];
  let poolV2Calls = [];

  const walletAddress = await getWalletAddress();  
  let isUserConnected = false;
  if(walletAddress !== null){
    isUserConnected = true;
  }

  const rawPools = poolInitialState.pools.filter(pool => pool.version === 'v2');

  rawPools.forEach(pool => {
    const stakedTokenAddress = getAddress(pool.stakedToken);
    const rewardTokenAddress = getAddress(pool.rewardToken);
    const networkTokenAddress = getAddress(process.env.REACT_APP_NETWORK_TOKEN);
    const stableTokenAddress = getAddress(process.env.REACT_APP_STABLE_TOKEN);
    const stakedTokenLpAddress = getLpAddress(pool.stakedToken, process.env.REACT_APP_NETWORK_TOKEN);
    const rewardTokenLpAddress = getLpAddress(pool.rewardToken, process.env.REACT_APP_NETWORK_TOKEN);
    const stableNetworkLpAddress = getLpAddress(process.env.REACT_APP_STABLE_TOKEN, process.env.REACT_APP_NETWORK_TOKEN);
    const poolAddress = pool.address;

    const calls = [
      {
        address: stakedTokenAddress,
        name: 'balanceOf',
        params: [stakedTokenLpAddress],
      },
      {
        address: networkTokenAddress,
        name: 'balanceOf',
        params: [stakedTokenLpAddress],
      },
      {
        address: rewardTokenAddress,
        name: 'balanceOf',
        params: [rewardTokenLpAddress],
      },
      {
        address: networkTokenAddress,
        name: 'balanceOf',
        params: [rewardTokenLpAddress],
      },
      {
        address: stakedTokenAddress,
        name: 'balanceOf',
        params: [poolAddress],
      },
      {
        address: stableTokenAddress,
        name: 'balanceOf',
        params: [stableNetworkLpAddress],
      },
      {
        address: networkTokenAddress,
        name: 'balanceOf',
        params: [stableNetworkLpAddress],
      },
      {
        address: stakedTokenAddress,
        name: 'decimals',
      },
      {
        address: rewardTokenAddress,
        name: 'decimals',
      },
      {
        address: stableTokenAddress,
        name: 'decimals',
      },
      {
        address: networkTokenAddress,
        name: 'decimals',
      },
    ];

    if(isUserConnected) {
      calls.push(
        {
          address: stakedTokenAddress,
          name: 'allowance',
          params: [
            walletAddress,
            poolAddress
          ],
        },
      );
      calls.push(
        {
          address: stakedTokenAddress,
          name: 'balanceOf',
          params: [walletAddress],
        },
      );
    }

    erc20Calls = [...erc20Calls, ...calls];

    const calls2 = [
      {
        address: poolAddress,
        name: 'startTime',
        params: [],
      },
      {
        address: poolAddress,
        name: 'endTime',
      },
      {
        address: poolAddress,
        name: 'rewardPerSecond',
      },
      {
        address: poolAddress,
        name: 'harvestInterval',
      },
      {
        address: poolAddress,
        name: 'withdrawInterval',
      },
      {
        address: poolAddress,
        name: 'withdrawWhenFinished',
      },
      {
        address: poolAddress,
        name: 'experienceRate',
      },
    ];

    if(isUserConnected) {
      calls2.push({
        address: poolAddress,
        name: 'canHarvest',
        params: [walletAddress],
      });
      calls2.push({
        address: poolAddress,
        name: 'pendingReward',
        params: [walletAddress],
      });
      // calls2.push({
      //   address: poolAddress,
      //   name: 'pendingExperience',
      //   params: [walletAddress],
      // });
      calls2.push({
        address: poolAddress,
        name: 'userInfo',
        params: [walletAddress],
      });
      calls2.push({
        address: poolAddress,
        name: 'canWithdraw',
        params: [walletAddress],
      });
    }

    poolV2Calls = [...poolV2Calls, ...calls2];
  });

  const erc20Results = await multicall(erc20Abi, erc20Calls);
  const poolV2Results = await multicall(poolsV2Abi, poolV2Calls);

  const erc20Length = erc20Results.length / rawPools.length;
  const poolV2Length = poolV2Results.length / rawPools.length;

  const nftIds = [];

  let tvl = ZERO;

  const newPools = rawPools.map((pool, i) => {
    const erc20Index = i * erc20Length;
    const poolV2Index = i * poolV2Length;

    const stakedTokenBalanceLP = new BigNumber(erc20Results[erc20Index + 0]);
    const stakedQuoteTokenBalanceLP = new BigNumber(erc20Results[erc20Index + 1]);
    const rewardTokenBalanceLP = new BigNumber(erc20Results[erc20Index + 2]);
    const rewardQuoteTokenBalanceLP = new BigNumber(erc20Results[erc20Index + 3]);
    const totalStaked = new BigNumber(erc20Results[erc20Index + 4]);
    const stableTokenBalanceLP = new BigNumber(erc20Results[erc20Index + 5]);
    const networkTokenBalanceLP = new BigNumber(erc20Results[erc20Index + 6]);
    const stakedTokenDecimals = erc20Results[erc20Index + 7][0];
    const rewardTokenDecimals = erc20Results[erc20Index + 8][0];
    const stableTokenDecimals = erc20Results[erc20Index + 9][0];
    const networkTokenDecimals = erc20Results[erc20Index + 10][0];
    const userAllowance = isUserConnected ? new BigNumber(erc20Results[erc20Index + 11]) : ZERO;
    const userBalance =  isUserConnected ? new BigNumber(erc20Results[erc20Index + 12]) : ZERO;

    const startTime = new BigNumber(poolV2Results[poolV2Index + 0]);
    const endTime = new BigNumber(poolV2Results[poolV2Index + 1]);
    const rewardPerSecond = new BigNumber(poolV2Results[poolV2Index + 2]);
    const harvestInterval = new BigNumber(poolV2Results[poolV2Index + 3]);
    const withdrawInterval = new BigNumber(poolV2Results[poolV2Index + 4]);
    const withdrawWhenFinished = poolV2Results[poolV2Index + 5][0];
    const experienceRate = new BigNumber(poolV2Results[poolV2Index + 6]);
    const userCanHarvest = isUserConnected ? poolV2Results[poolV2Index + 7][0] : false;
    const userPendingReward = isUserConnected ? new BigNumber(poolV2Results[poolV2Index + 8]) : ZERO;
    const userAmount = isUserConnected ? new BigNumber(poolV2Results[poolV2Index + 9][0]._hex) : ZERO;
    const userNextHarvestUntil = isUserConnected ? new BigNumber(poolV2Results[poolV2Index + 9][3]._hex) : ZERO;
    const userNextWithdrawUntil = isUserConnected ? new BigNumber(poolV2Results[poolV2Index + 9][4]._hex) : ZERO;
    const userNftID = isUserConnected ? new BigNumber(poolV2Results[poolV2Index + 9][5]._hex) : ZERO;
    const userExperience = isUserConnected ? new BigNumber(poolV2Results[poolV2Index + 9][8]._hex) : ZERO;
    const userHasNFT = isUserConnected ? poolV2Results[poolV2Index + 9][9] : false;
    const userCanWithdraw = isUserConnected ? poolV2Results[poolV2Index + 10][0] : false;

    const networkStablePrice = stableTokenBalanceLP
      .times(new BigNumber(10).pow(networkTokenDecimals - stableTokenDecimals))
      .div(networkTokenBalanceLP);

    let stakedTokenPrice = stakedQuoteTokenBalanceLP.div(stakedTokenBalanceLP);
    if (stakedTokenPrice.isNaN() || !stakedTokenPrice.isFinite()) {
      stakedTokenPrice = new BigNumber(pool.stakedTokenDefaultPrice).div(networkStablePrice);
    }
    const rewardTokenPrice = rewardQuoteTokenBalanceLP.div(rewardTokenBalanceLP);

    const totalRewardPricePerYear = rewardTokenPrice
      .times(rewardPerSecond.div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS)))
      .times(process.env.REACT_APP_SECONDS_PER_YEAR);

    const totalStakingTokenInPool = stakedTokenPrice
      .times(totalStaked.div(new BigNumber(10).pow(stakedTokenDecimals)));

    let apr = totalRewardPricePerYear;

    if (totalStakingTokenInPool.gt(0)) {
      apr = apr.div(totalStakingTokenInPool);
    }

    const currentTime = Date.now() / 1000;
    const seconds = endTime.minus(startTime);
    const withdrawLockup = seconds.div(process.env.REACT_APP_SECONDS_PER_HOUR);
    const poolReward = seconds.times(rewardPerSecond.div(new BigNumber(10).pow(rewardTokenDecimals)));
    const isActive = endTime.gt(currentTime);

    tvl = tvl.plus(totalStaked.div(new BigNumber(10).pow(stakedTokenDecimals))
      .times(new BigNumber(stakedTokenPrice))
      .times(new BigNumber(networkStablePrice)));

    if (userHasNFT) {
      nftIds.push(userNftID);
    }

    return {
      ...pool,
      apr: apr.toJSON(),
      stakedTokenPrice: stakedTokenPrice.toJSON(),
      rewardTokenPrice: rewardTokenPrice.toJSON(),
      networkStablePrice: networkStablePrice.toJSON(),
      stakedTokenBalanceLP: stakedTokenBalanceLP.toJSON(),
      stakedQuoteTokenBalanceLP: stakedQuoteTokenBalanceLP.toJSON(),
      rewardTokenBalanceLP: rewardTokenBalanceLP.toJSON(),
      rewardQuoteTokenBalanceLP: rewardQuoteTokenBalanceLP.toJSON(),
      totalStaked: totalStaked.toJSON(),
      stableTokenBalanceLP: stableTokenBalanceLP.toJSON(),
      networkTokenBalanceLP: networkTokenBalanceLP.toJSON(),
      stakedTokenDecimals,
      rewardTokenDecimals,
      stableTokenDecimals,
      networkTokenDecimals,
      userAllowance: userAllowance.toJSON(),
      userBalance: userBalance.toJSON(),
      startTime: startTime.toJSON(),
      endTime: endTime.toJSON(),
      userCanWithdraw,
      rewardPerSecond: rewardPerSecond.toJSON(),
      harvestInterval: harvestInterval.toJSON(),
      withdrawInterval: withdrawInterval.toJSON(),
      withdrawWhenFinished,
      experienceRate: experienceRate.toJSON(),
      userCanHarvest,
      userPendingReward: userPendingReward.toJSON(),
      userAmount: userAmount.toJSON(),
      userHasNFT,
      userNftID: userNftID.toJSON(),
      userExperience: userExperience.toJSON(),
      userNextHarvestUntil: userNextHarvestUntil.toJSON(),
      userNextWithdrawUntil: userNextWithdrawUntil.toJSON(),
      withdrawLockup: withdrawLockup.toJSON(),
      poolReward: poolReward.toJSON(),
      poolRewardStable: poolReward.times(rewardTokenPrice).times(networkStablePrice).toJSON(),
      isActive,
    }
  });

  let nfts = [];
  if (nftIds.length > 0) {
    nfts = await getNfts(nftIds);
  }

  const pools = newPools.map(pool => ({
    ...pool,
    selectedNft: nfts.find(card => pool.userHasNFT && pool.userNftID === card.pid),
  }));

  return {
    pools,
    tvl: tvl.toJSON(),
    firstLoad: false,
  };
};


export const approvePool = async (address, token) => {
  const tokenContract = await getSignedContract(token, erc20Abi);

  return await tokenContract.approve(address, ethers.constants.MaxUint256);
}

export const withdrawPool = async (address, amount) => {
  const poolContract = await getSignedContract(address, poolsV2Abi);

  return await poolContract.withdraw(amount);
}

export const depositPool = async (address, amount) => {
  const poolContract = await getSignedContract(address, poolsV2Abi);

  return await poolContract.deposit(amount);
}

export const harvestPool = async (address) => {
  return await depositPool(address, '0');
}

export const withdrawPoolNft = async (address) => {
  const poolContract = await getSignedContract(address, poolsV2Abi);
  return await poolContract.withdrawNFT();
}

export const depositPoolNft = async (address, nftPid) => {
  const poolContract = await getSignedContract(address, poolsV2Abi);
  return await poolContract.addNFT(nftPid);
}
