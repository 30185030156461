import React from 'react';

const NftInfo = () => {
  return (
    <div className="message is-info">
      <div className="message-body content">
        <h4>WHAT YOU CAN DO WITH YOUR NFT ARTWORK</h4>
        <ul>
          <li>BOOST your REWARDS (FARMING and WALLS)</li>
          <li>REDUCE your Harvest Lockup interval</li>
          <li>VOTE for make decisions in our Platform</li>
          <li>GAIN EXPERIENCE as you use the artwork.</li>
          {/* <li>EPIC BATTLES with other users.</li> */}
          <li>MERGE many artworks to create a new BOOSTED one.</li>
        </ul>
      </div>
    </div>
  );
}

export default NftInfo;
