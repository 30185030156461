import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import multicall from '../blockchain/multicall';
import liquidityToolBoxAbi from '../config/abis/liquidityToolBox.json';
import erc20Abi from '../config/abis/erc20.json';
import { getAddress, getLpAddress } from '../utils/commons';
import { getSignedContract, getWalletAddress } from './commons';

const ZERO = new BigNumber(0);

export const fetchLiquidityToolBoxAdd = async (token, quote) => {
  const liquidityToolBoxAddress = getAddress('liquidityToolBox');
  const tokenAddress = getAddress(token);
  const quoteAddress = getAddress(quote);
  const lpAddress = getLpAddress(token, quote);

  const walletAddress = await getWalletAddress();
  const isUserConnected = walletAddress !== null;

  const erc20Calls = [
    {
      address: tokenAddress,
      name: 'balanceOf',
      params: [lpAddress],
    },
    {
      address: quoteAddress,
      name: 'balanceOf',
      params: [lpAddress],
    },
    {
      address: tokenAddress,
      name: 'decimals',
    },
    {
      address: quoteAddress,
      name: 'decimals',
    },
  ];

  if (isUserConnected) {
    erc20Calls.push({
      address: tokenAddress,
      name: 'balanceOf',
      params: [walletAddress],
    });
    erc20Calls.push({
      address: quoteAddress,
      name: 'balanceOf',
      params: [walletAddress],
    });
    erc20Calls.push({
      address: tokenAddress,
      name: 'allowance',
      params: [walletAddress, liquidityToolBoxAddress],
    });
    erc20Calls.push({
      address: quoteAddress,
      name: 'allowance',
      params: [walletAddress, liquidityToolBoxAddress],
    });
  }

  const erc20Results = await multicall(erc20Abi, erc20Calls);

  const tokenLpBalance = new BigNumber(erc20Results[0]);
  const quoteLpBalance = new BigNumber(erc20Results[1]);
  const tokenDecimals =  erc20Results[2][0];
  const quoteDecimals =  erc20Results[3][0];

  const priceTokenVsQuote = (tokenLpBalance.div(new BigNumber(10).pow(tokenDecimals)))
    .div(quoteLpBalance.div(new BigNumber(10).pow(quoteDecimals)));

  const priceQuoteVsToken = (quoteLpBalance.div(new BigNumber(10).pow(quoteDecimals)))
    .div(tokenLpBalance.div(new BigNumber(10).pow(tokenDecimals)));

  return {
    priceTokenVsQuote: priceTokenVsQuote.toJSON(),
    priceQuoteVsToken: priceQuoteVsToken.toJSON(),
    tokenDecimals,
    quoteDecimals,
    userTokenBalance: isUserConnected ? new BigNumber(erc20Results[4]).div(new BigNumber(10).pow(tokenDecimals)).toJSON() : ZERO,
    userQuoteBalance: isUserConnected ? new BigNumber(erc20Results[5]).div(new BigNumber(10).pow(quoteDecimals)).toJSON() : ZERO,
    userTokenAllowance: isUserConnected ? new BigNumber(erc20Results[6]).toJSON() : ZERO,
    userQuoteAllowance: isUserConnected ? new BigNumber(erc20Results[7]).toJSON() : ZERO,
  };
};

export const fetchLiquidityToolBoxRemove = async (token, quote) => {
  const walletAddress = await getWalletAddress();
  const isUserConnected = walletAddress !== null;

  if (!isUserConnected) {
    return {
      userLpBalance: ZERO,
      userLpAllowance: ZERO,
    };
  }

  const liquidityToolBoxAddress = getAddress('liquidityToolBox');
  const lpAddress = getLpAddress(token, quote);

  const erc20Calls = [
    {
      address: lpAddress,
      name: 'balanceOf',
      params: [walletAddress],
    },
    {
      address: lpAddress,
      name: 'allowance',
      params: [walletAddress, liquidityToolBoxAddress],
    },
  ];

  const erc20Results = await multicall(erc20Abi, erc20Calls);

  const userLpBalance = new BigNumber(erc20Results[0])
    .div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS));

  return {
    userLpBalance: userLpBalance.toJSON(),
    userLpAllowance: new BigNumber(erc20Results[1]).toJSON(),
  };
};

export const approveLiquidityToolBox = async (token) => {
  const tokenContract = await getSignedContract(token, erc20Abi);
  return await tokenContract.approve(getAddress('liquidityToolBox'), ethers.constants.MaxUint256.toString());
};

export const addLiquidity = async (baseTokenAddress, baseAmount, nativeAmount) => {
  const liquidityToolBoxContract = await getSignedContract('liquidityToolBox', liquidityToolBoxAbi);
  return await liquidityToolBoxContract.addBanksyLiquidity(baseTokenAddress, baseAmount, nativeAmount);
}

export const removeLiquidity = async (baseTokenAddress, liquidity) => {
  const liquidityToolBoxContract = await getSignedContract('liquidityToolBox', liquidityToolBoxAbi);
  return await liquidityToolBoxContract.removeBanksyLiquidity(baseTokenAddress, liquidity);
}
