import React, { createContext, useState } from 'react';

import farmInitialState from '../state/farmInitialState';

export const FarmsContext = createContext();

export const FarmsProvider = (props) => {
  const [farmState, setFarmState] = useState(farmInitialState);

  return (
    <FarmsContext.Provider value={{ farmState, setFarmState }}>
      { props.children }
    </FarmsContext.Provider>
  );
}
