import React, { useState, useContext, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import Countdown from 'react-countdown';

import { nativeTokenName, stableTokenName } from '../../../utils/commons';

import  { harvestFarm, depositFarm } from '../../../blockchain/farms';

import { ToastContext } from '../../../context/toast';

const pad = (num) => {
  if (num < 10) {
    return `0${num}`;
  }

  return num;
}

const renderer = ({ hours, minutes }) => {
  return (
    <span>
      { pad(hours) }:{ pad(minutes) }
    </span>
  );
};

const rendererSec = ({ hours, minutes, seconds }) => {
  return (
    <span>
      { pad(hours) }:{ pad(minutes) }:{pad(seconds)}
    </span>
  );
};

const FarmHarvestSection = ({ farmData, pendingUsd, pendingExtraBanksy, nativeTokenPrice }) => {
  const { addToast } = useContext(ToastContext);

  const [pendingHarvestTx, setPendingHarvestTx] = useState(false);

  const earned = () => {
    const earnedValue = new BigNumber(farmData.pendingToken)
      .div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS));

    return earnedValue.toFormat(earnedValue.eq(0) || earnedValue.gt(1) ? 2 : 6);
  }

  const earnedInUSDC = () => {
    const earnedValue = new BigNumber(farmData.pendingToken)
      .div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS))
      .times(nativeTokenPrice);

    return earnedValue.toFormat(earnedValue.eq(0) || earnedValue.gt(1) ? 2 : 6);
  }

  const earnedUSD = () => {
    const earnedValue = new BigNumber(pendingUsd)
      .div(new BigNumber(10).pow(6));

    return earnedValue.toFormat(earnedValue.eq(0) || earnedValue.gt(1) ? 2 : 6);
  }

  const earnedExtraBanksy = () => {
    const earnedValue = new BigNumber(pendingExtraBanksy)
      .div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS));

    return earnedValue.toFormat(earnedValue.eq(0) || earnedValue.gt(1) ? 2 : 6);
  }

  const canHarvest = useMemo(() => {
    return farmData.userCanHarvest && new BigNumber(farmData.pendingToken).gt(0);
  }, [farmData.userCanHarvest, farmData.pendingToken]);

  const handleHarvest = async () => {
    let tx;
    try {
      setPendingHarvestTx(true);
      tx = await harvestFarm(farmData.pid);
      await tx.wait();
      addToast('Harvest succeded', 'is-success');
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }
    if (tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('Harvest failed', 'is-danger');
    }

    setPendingHarvestTx(false);
  }

  const handleCompound = async () => {
    setPendingHarvestTx(true);
    let tx;
    try {
      tx = await depositFarm(farmData.pid, new BigNumber(farmData.pendingToken).toFixed());
      await tx.wait();
      addToast('Successfull Compound!', 'is-success');
    } catch(error) {
      tx = { error: error.data?.message || error.message };
    }
    if(tx.error !== undefined) {
      addToast(tx.error, 'is-danger');
    }

    setPendingHarvestTx(false);
  }

  const renderNftLockup = () => {
    if (!farmData.userHasNft) {
      return null;
    }

    const experience = new BigNumber(farmData.selectedNft.experience).div(10);
    const quarter = new BigNumber(farmData.harvestInterval).times(0.25);
    const extra = experience.gt(quarter) ? quarter : experience;
    const lockup = new BigNumber(farmData.harvestInterval).minus(quarter).minus(extra);

    return (
      <span className="ml-1 has-text-success">
        <Countdown date={ lockup.times(1000).plus(Date.now()).toNumber() } renderer={ renderer } autoStart={ false } />
      </span>
    );
  }

  const renderLockup = () => {
    return (
      <>
        <span className={ `ml-1 has-text-danger ${farmData.userHasNft ? 'is-line-through' : ''}` }>
          <Countdown date={ new BigNumber(farmData.harvestInterval).times(1000).plus(Date.now()).toNumber() } renderer={ renderer } autoStart={ false } />
        </span>
        { renderNftLockup() }
      </>
    );
  }

  const renderHarvestOrCountdown = () => {
    if (!farmData.userCanHarvest) {
      return (
        <Countdown date={ new BigNumber(farmData.userNextHarvestUntil).times(1000).toNumber() } renderer={ rendererSec } />
      );
    }

    return 'HARVEST';
  }

  return (
    <>
      <div className="level is-mobile mb-0">
        <div className="level-left">
          <div className="level-item">
            EARNED
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            LOCKUP { renderLockup() }
          </div>
        </div>
      </div>
      <div className="level is-mobile">
        <div className="level-left">
          <div className="level-item">
            <div className="is-flex is-align-items-center">
            {farmData.pid === 0 ? <span>+</span> : null}
              <div className="is-flex is-flex-direction-column">
                <div className="is-flex is-align-items-center">
                  {farmData.pid === 0 ? (
                    <figure className="image is-24x24 mr-1" data-tooltip={ nativeTokenName }>
                      <img src={ `/images/farms/${process.env.REACT_APP_NATIVE_TOKEN}.png`.toLowerCase() } alt={ nativeTokenName } />
                    </figure>
                  ) : null}
                  <h5 className="is-size-5 has-text-weight-semibold">
                    { earned() }<br />
                    ~${ earnedInUSDC() }
                  </h5>
                </div>
                {farmData.pid === 0 ? (
                  <>
                    <div className="is-flex is-align-items-center">
                      <figure className="image is-24x24 mr-1" data-tooltip={ nativeTokenName }>
                        <img src={ `/images/farms/${process.env.REACT_APP_STABLE_TOKEN}.png`.toLowerCase() } alt={ stableTokenName } />
                      </figure>
                      <h5 className="is-size-5 has-text-weight-semibold">
                        { earnedUSD() }
                      </h5>
                    </div>
                    <div className="is-flex is-align-items-center">
                      <figure className="image is-24x24 mr-1" data-tooltip={ nativeTokenName }>
                        <img src={ `/images/farms/${process.env.REACT_APP_NATIVE_TOKEN}.png`.toLowerCase() } alt={ nativeTokenName } />
                      </figure>
                      <h5 className="is-size-5 has-text-weight-semibold">
                        { earnedExtraBanksy() }
                      </h5>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <div className="is-flex is-flex-direction-column">
              {farmData.name === nativeTokenName ? (
                <button
                  disabled={ pendingHarvestTx || !canHarvest }
                  className={ `button is-primary is-outlined mb-1 ${pendingHarvestTx ? 'is-loading' : ''}` }
                  onClick={ handleCompound }
                >
                  COMPOUND
                </button>
              ) : null}
              <button
                disabled={ pendingHarvestTx || !canHarvest }
                className={ `button is-primary ${pendingHarvestTx ? 'is-loading' : ''}` }
                onClick={ handleHarvest }
              >
                { renderHarvestOrCountdown() }
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FarmHarvestSection;
