import React, { createContext, useState } from 'react';

import uxInitialState from '../state/init/uxInitialState';

export const UxContext = createContext();

export const UxProvider = (props) => {
  const [uxState, setUxState] = useState(uxInitialState);

  return (
    <UxContext.Provider value={{ uxState, setUxState }}>
      { props.children }
    </UxContext.Provider>
  );
}
