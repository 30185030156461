import React, { createContext, useEffect, useState } from 'react';
import { fetchNativeTokenPrice } from '../blockchain/nativeTokenPrice';

export const NativeTokenPriceContext = createContext();

export const NativeTokenPriceProvider = (props) => {
  const [nativeTokenPrice, setNativeTokenPrice] = useState({
    amount: 0,
    default: false,
  });

  useEffect(() => {
    const syncNativeTokenPrice = async () => {
      const newNativeTokenPrice = await fetchNativeTokenPrice();
      setNativeTokenPrice(prevState => ({ ...prevState, ...newNativeTokenPrice }));
    }

    const intervalId = setInterval(syncNativeTokenPrice, 20000);

    syncNativeTokenPrice();

    return () => clearInterval(intervalId);
  }, [setNativeTokenPrice]);

  return (
    <NativeTokenPriceContext.Provider value={{ nativeTokenPrice }}>
      { props.children }
    </NativeTokenPriceContext.Provider>
  );
}
