import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const NftNav = () => {
  const location = useLocation();

  return (
    <nav className="section has-background-light pt-3 pb-0 has-border-bottom is-hidden-mobile">
      <div className="container">
        <div className="tabs mb-npx">
          <ul>
            <li className={ location.pathname === '/my-nfts' ? 'is-active' : '' }>
              <Link to="/my-nfts">My NFTs</Link>
            </li>
            <li className={ location.pathname === '/nft-merge' ? 'is-active' : '' }>
              <Link to="/nft-merge">
                Merge
                {/* <span className="tag is-small is-primary ml-2">live</span> */}
              </Link>
            </li>
            <li className={ location.pathname === '/nft-sale' ? 'is-active' : '' }>
              <Link to="/nft-sale">
                Sale
                {/* <span className="tag is-small is-primary ml-2">live</span> */}
              </Link>
            </li>
            <li className={ location.pathname === '/nft-airdrop' ? 'is-active' : '' }>
              <Link to="/nft-airdrop">
                Airdrop
                {/* <span className="tag is-small is-primary ml-2">live</span> */}
              </Link>
            </li>
            <li className={ location.pathname === '/nft-ranking' ? 'is-active' : '' }>
              <Link to="/nft-ranking">Ranking</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NftNav;
