import React, { useContext, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import EarnNav from '../earn/components/EarnNav';

import { nativeTokenName } from '../../utils/commons';

import PoolCard from './components/PoolCard/PoolCard';
import PoolWithdrawModal from './components/PoolWithdrawModal';
import PoolDepositModal from './components/PoolDepositModal';
import PoolDepositNftModal from './components/PoolDepositNftModal';
import NftPreviewModal from '../nft/components/NftPreviewModal';

import { fetchPools } from '../../blockchain/pools';
import { PoolsContext } from '../../context/pools';

import { NftContext } from '../../context/nft';
import { fetchMyNfts } from '../../blockchain/nft';

import './PoolsPage.css';

const PoolsPage = () => {
  const filters = JSON.parse(localStorage.getItem('poolsFilters'));

  const { poolState, setPoolState } = useContext(PoolsContext);

  const { setNftState } = useContext(NftContext);

  const [stakedOnly, setStakedOnly] = useState(filters ? filters.stakedOnly : false);
  const [activeOnly, setActiveOnly] = useState(filters ? filters.activeOnly : true);

  const saveFilters = (values) => {
    localStorage.setItem('poolsFilters', JSON.stringify({
      stakedOnly,
      activeOnly,
      ...values,
    }));
  }

  const filteredPools = () => {
    const newPools = poolState.pools.filter(pool => {
      let show = true;
      if (show && stakedOnly) {
        show = new BigNumber(pool.userAmount).gt(0);
      }
      if (show && activeOnly) {
        show = pool.isActive;
      } else {
        show = !pool.isActive;
      }
 
      return show;
    });

    return newPools;
  }

  const onToggleStakedOnly = () => {
    setStakedOnly(prevState => !prevState);
    saveFilters({ stakedOnly: !stakedOnly });
  }

  const [selectedNft, setSelectedNft] = useState(null);

  const [isNftPreviewModalActive, setIsNftPreviewModalActive] = useState(false);
  const handleNftPreviewModalClose = () => setIsNftPreviewModalActive(false);
  const handleNftPreviewModalOpen = (nft) => {
    setSelectedNft(nft);
    setIsNftPreviewModalActive(true);
  }

  const [selectedPoolWithdraw, setSelectedPoolWithdraw] = useState(null);
  const [isWithdrawModalActive, setIsWithdrawModalActive] = useState(false);

  const handleWithdrawModalClose = () => {
    setIsWithdrawModalActive(false);
    setSelectedPoolWithdraw(null);
  }

  const handleWithdrawModalOpen = (pool) => {
    setSelectedPoolWithdraw(pool);
    setIsWithdrawModalActive(true);
  }

  const [selectedPoolDeposit, setSelectedPoolDeposit] = useState(null);
  const [isDepositModalActive, setIsDepositModalActive] = useState(false);

  const handleDepositModalClose = () => {
    setIsDepositModalActive(false);
    setSelectedPoolDeposit(null);
  }

  const handleDepositModalOpen = (pool) => {
    setSelectedPoolDeposit(pool);
    setIsDepositModalActive(true);
  }

  const [selectedPoolDepositNft, setSelectedPoolDepositNft] = useState(null);
  const [isDepositNftModalActive, setIsDepositNftModalActive] = useState(false);

  const handleDepositNftModalClose = () => {
    setIsDepositNftModalActive(false);
    setSelectedPoolDepositNft(null);
  }

  const handleDepositNftModalOpen = (pool) => {
    setSelectedPoolDepositNft(pool);
    setIsDepositNftModalActive(true);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const syncPools = async () => {
      // console.log('>> Sync Pools List >>');
      setPoolState(prevState => ({ ...prevState, loading: true }));
      const newPoolState = await fetchPools();
      setPoolState(prevState => ({ ...prevState, ...newPoolState, loading: false }));
    }

    const intervalId = setInterval(syncPools, 10000);

    syncPools();

    return () => clearInterval(intervalId);
  }, [setPoolState]);

  useEffect(() => {
    const syncMyNfts = async () => {
      setNftState(prevState => ({ ...prevState, loading: true }));
      const nftResult = await fetchMyNfts();
      setNftState(prevState => ({ ...prevState, ...nftResult, loading: false }));
    }

    const intervalId = setInterval(syncMyNfts, 10000);

    syncMyNfts();

    return () => clearInterval(intervalId);
  }, [setNftState]);

  const renderPools = () => {
    if (poolState.firstLoad && poolState.loading) {
      return (
        <div className="has-text-centered">
          <span className="icon-text is-align-items-center">
            <span className="icon is-large">
              <FontAwesomeIcon icon={ faCog } spin size="2x" />
            </span>
            <span>Loading...</span>
          </span>
        </div>
      );
    }

    return (
      <div className="columns is-centered is-multiline">
        {filteredPools().map(pool => (<PoolCard key={ pool.pid } poolData={ pool } handleWithdrawModalOpen={ handleWithdrawModalOpen } handleDepositModalOpen={ handleDepositModalOpen } handleDepositNftModalOpen={ handleDepositNftModalOpen } handleNftPreviewModalOpen={ handleNftPreviewModalOpen } />))}
      </div>
    );
  }

  return (
    <>
      <div className="parallax" style={{ backgroundImage: 'url("/images/parallax/bg-0.png")' }} />
      <EarnNav />
      <header className="hero is-relative">
        <div className="parallax-mobile" style={{ backgroundImage: 'url("/images/parallax/bg-0.png")' }} />
        <div className="hero-body">
          <div className="container">
            <div className="hero-box has-text-centered">
              <p className="title">Walls</p>
              <p className="subtitle">
                Stake <span className="has-text-primary">{ nativeTokenName }</span> and earn stable coins.<br />
                Reduce Harvest and Withdraw Lockup, earn Experience,<br />
                and Boost your rewards by using your<span className="has-text-primary"> 3D NFTs ArtWork</span><br />
                Full Harvest Fee will be distributed through the <span className="has-text-primary">Owner's Pool</span>.
              </p>
            </div>
          </div>
        </div>
      </header>
      <main role="main" className="section has-background-white">
        <div className="container">
          <div className="columns is-justify-content-center is-align-items-center">
            <div className="column is-narrow">
              <div className="field">
                <input id="stakedOnly" type="checkbox" name="stakedOnly" className="switch is-rounded is-outlined" checked={ stakedOnly } onChange={ onToggleStakedOnly } />
                <label htmlFor="stakedOnly">Staked Only</label>
              </div>
            </div>
            <div className="column is-narrow">
              <div className="buttons has-addons">
                <button
                  onClick={() => {
                    if (!activeOnly) {
                      setActiveOnly(true);
                      saveFilters({ activeOnly: true });
                    }
                  }}
                  className={`button is-rounded ${activeOnly ? 'is-success is-selected' : ''}`}
                >
                  Active
                </button>
                <button
                  onClick={() => {
                    if (activeOnly) {
                      setActiveOnly(false);
                      saveFilters({ activeOnly: false });
                    }
                  }}
                  className={`button is-rounded ${activeOnly ? '' : 'is-success is-selected'}`}
                >
                  Finished
                </button>
              </div>
            </div>
          </div>
          { renderPools() }
        </div>
      </main>
      <div className="parallax-bottom" />

      {selectedPoolWithdraw ? (
        <PoolWithdrawModal
          poolData={ selectedPoolWithdraw }
          isModalActive={ isWithdrawModalActive }
          onModalClose={ handleWithdrawModalClose }
        />
      ) : null}
      {selectedPoolDeposit ? (
        <PoolDepositModal
          poolData={ selectedPoolDeposit }
          isModalActive={ isDepositModalActive }
          onModalClose={ handleDepositModalClose }
        />
      ) : null}
      {selectedPoolDepositNft ? (
        <PoolDepositNftModal
          poolData={ selectedPoolDepositNft }
          isModalActive={ isDepositNftModalActive }
          onModalClose={ handleDepositNftModalClose }
        />
      ) : null}
      {selectedNft ? (
        <NftPreviewModal
          nftData={ selectedNft }
          isNftPreviewModalActive={ isNftPreviewModalActive }
          onNftPreviewModalClose={ handleNftPreviewModalClose }
        />
      ) : null}
    </>
  );
}

export default PoolsPage;
