import BigNumber from 'bignumber.js/bignumber'
import { NativeTokenPriceProvider } from './context/nativeTokenPrice';
import { NftProvider } from './context/nft';
import { FarmsProvider } from './context/farms';
import { PoolsProvider } from './context/pools';
import { VaultsProvider } from './context/vaults';
import { WalletProvider } from './context/wallet';
import { UxProvider } from './context/ux';
import { ToastProvider } from './context/toast';
import { VrProvider } from './context/vr';

import Layout from './layout/Layout';

import './i18n';

import './App.scss';

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

const App = () => {
  return (
    <UxProvider>
      <ToastProvider>
        <VrProvider>
          <WalletProvider>
            <NativeTokenPriceProvider>
              <NftProvider>
                <FarmsProvider>
                  <PoolsProvider>
                    <VaultsProvider>
                      <Layout/>
                    </VaultsProvider>
                  </PoolsProvider>
                </FarmsProvider>
              </NftProvider>
            </NativeTokenPriceProvider>
          </WalletProvider>
        </VrProvider>
      </ToastProvider>
    </UxProvider>
  );
}

export default App;
