import React from 'react';
import Countdown from 'react-countdown';
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAddress, getLpAddress, swapAddress } from '../../../../../utils/commons';

const FarmRowDetailsSection = ({ farmData, nativeTokenPriceDefault }) => {
  const linkToViewOn = () => {
    let token;
    if (farmData.isTokenOnly) {
      token = getAddress(farmData.token);
    } else {
      token = getLpAddress(farmData.token, farmData.quoteToken);
    }

    return `${process.env.REACT_APP_EXPLORER}/token/${token}`;
  }

  const optionalRender = () => {
    if (nativeTokenPriceDefault) {
      return (
        <div>
          <p>Staking starts at <Countdown date={ Number(process.env.REACT_APP_LAUNCH_AT) * 1000 } /></p>
        </div>

      );
    }

    return (
      <div>
        <p className="is-flex is-align-items-center px-4 mb-1">
          Deposit Fee <strong className="is-size-6 has-text-weight-semibold ml-2">{ farmData.depositFeeBP / 100 }%</strong>
        </p>
        <p>
          <a className="button is-ghost" disabled={ farmData.isNative && nativeTokenPriceDefault } href={ swapAddress(farmData) } target="_blank" rel="noreferrer">
            <span className="icon-text">
              Get { farmData.name }
              <span className="icon ml-1">
                <FontAwesomeIcon icon={ faExternalLinkAlt } />
              </span>
            </span>
          </a>
        </p>
        <p>
          <a href={ linkToViewOn() } className="button is-ghost" target="_blank" rel="noreferrer">
            <span className="icon-test">
              View on Explorer
              <span className="icon ml-1">
                <FontAwesomeIcon icon={ faExternalLinkAlt } />
              </span>
            </span>
          </a>
        </p>
      </div>
    );
  }

  return optionalRender();
}

export default FarmRowDetailsSection;
