const poolInitialState = {
  loading: false,
  tvl: 0,
  maxPoolApr: 0,
  firstLoad: true,
  pools: [
    // {
    //   pid: 4,
    //   address: '0x93AD45B04D3Eed7044325954e1C388981CC0a6b0',
    //   stakedToken: 'banksy',
    //   rewardToken: 'dai',
    //   name: 'BANKSY DAI WALL',
    //   projectLink: 'https://makerdao.com/en/',
    //   category: 'CORE',
    //   version: 'v2',
    // },
    // {
    //   pid: 3,
    //   address: '0x1420A9305d88B7EAf639a06C0E8e39C4ea374a2E',
    //   stakedToken: 'banksy',
    //   rewardToken: 'dai',
    //   name: 'BANKSY DAI WALL',
    //   projectLink: 'https://makerdao.com/en/',
    //   category: 'CORE',
    //   version: 'v1',
    // },
    // {
    //   pid: 2,
    //   address: '0x2F1A81d3Daae4a6d2e592a97e81B364aA5D9C4c9',
    //   stakedToken: 'banksy',
    //   rewardToken: 'dai',
    //   name: 'BANKSY DAI WALL',
    //   projectLink: 'https://makerdao.com/en/',
    //   category: 'CORE',
    //   version: 'v1',
    // },
    {
      pid: 1,
      address: '0x86cc0cd13B3005eaafdb2D58BbC6CA5ceb4D2bAd',
      stakedToken: 'preBanksy',
      rewardToken: 'dai',
      name: 'PRE-BANKSY DAI WALL',
      projectLink: 'https://makerdao.com/en/',
      category: 'CORE',
      version: 'v2',
      stakedTokenDefaultPrice: 20.00,
    },
  ],
};

export default poolInitialState;
