import React, { createContext, useState } from 'react';

export const VrContext = createContext();

export const VrProvider = (props) => {
  const [isVrModalOpen, setVrModalOpen] = useState(false);

  const toggleVrModal = () => {
    setVrModalOpen(prevState => !prevState);
  }

  return (
    <VrContext.Provider value={{ isVrModalOpen, toggleVrModal }}>
      { props.children }
    </VrContext.Provider>
  );
}
