import React, { useContext } from 'react';

import { VrContext } from '../../../context/vr';

const VrModal = () => {
  const { isVrModalOpen, toggleVrModal } = useContext(VrContext);

  return (
    <div className={ `modal ${isVrModalOpen ? 'is-active' : ''}` }>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">The DeFi Boy</p>
          <button className="delete" aria-label="close" onClick={ toggleVrModal }></button>
        </header>
        <section className="modal-card-body">

          <figure className="image is-16by9">
            <iframe
              title="The Defi Boy by BanksyDAO Finance"
              className="has-ratio"
              width="640"
              height="360"
              frameBorder="0"
              allowFullScreen
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              allow="autoplay; fullscreen; xr-spatial-tracking"
              xr-spatial-tracking
              execution-while-out-of-viewport
              execution-while-not-rendered
              web-share
              src="https://sketchfab.com/models/50ebbb4323274a58806e57ff4e52ddf1/embed?autostart=1"
            ></iframe>
          </figure>

        </section>
        <footer className="modal-card-foot">
          <button className="button" onClick={ toggleVrModal }>Close</button>
        </footer>
      </div>
    </div>
  );
}

export default VrModal;
