import React, { useContext, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import EarnNav from '../earn/components/EarnNav';

import { nativeTokenName, stableTokenName } from '../../utils/commons';

import FarmFilter from './components/FarmFilter/FarmFilter';
import FarmCard from './components/FarmCard/FarmCard';
import FarmRow from './components/FarmRow/FarmRow';
import FarmRoiCalculatorModal from './components/FarmRoiCalculatorModal';
import FarmWithdrawModal from './components/FarmWithdrawModal';
import FarmDepositModal from './components/FarmDepositModal';
import FarmDepositNftModal from './components/FarmDepositNftModal';
import NftPreviewModal from '../nft/components/NftPreviewModal';

import { FarmsContext } from '../../context/farms';
import { fetchFarms } from '../../blockchain/farms';
import './FarmsPage.css';

const FarmsPage = ({ isTokenOnly }) => {
  const filters = JSON.parse(localStorage.getItem('farmsFilters'));

  const { farmState, setFarmState } = useContext(FarmsContext);

  const [isListView, setListView] = useState(filters ? filters.isListView : true);
  const [stakedOnly, setStakedOnly] = useState(filters ? filters.stakedOnly : false);
  const [activeOnly, setActiveOnly] = useState(filters ? filters.activeOnly : true);
  const [sort, setSort] = useState(filters ? filters.sort : 'hot');
  const [search, setSearch] = useState(filters ? filters.search : '');

  const saveFilters = (values) => {
    localStorage.setItem('farmsFilters', JSON.stringify({
      isListView,
      stakedOnly,
      activeOnly,
      sort,
      search,
      ...values,
    }));
  }

  const [selectedFarmRoiCalculator, setSelectedFarmRoiCalculator] = useState(null);
  const [isRoiCalculatorModalActive, setIsRoiCalculatorModalActive] = useState(false);

  const handleRoiCalculatorModalClose = () => {
    setIsRoiCalculatorModalActive(false);
    setSelectedFarmRoiCalculator(null);
  }

  const handleRoiCalculatorModalOpen = (farm) => {
    setSelectedFarmRoiCalculator(farm);
    setIsRoiCalculatorModalActive(true);
  }

  const [selectedFarmWithdraw, setSelectedFarmWithdraw] = useState(null);
  const [isWithdrawModalActive, setIsWithdrawModalActive] = useState(false);

  const handleWithdrawModalClose = () => {
    setIsWithdrawModalActive(false);
    setSelectedFarmWithdraw(null);
  }

  const handleWithdrawModalOpen = (farm) => {
    setSelectedFarmWithdraw(farm);
    setIsWithdrawModalActive(true);
  }

  const [selectedFarmDeposit, setSelectedFarmDeposit] = useState(null);
  const [isDepositModalActive, setIsDepositModalActive] = useState(false);

  const handleDepositModalClose = () => {
    setIsDepositModalActive(false);
    setSelectedFarmDeposit(null);
  }

  const handleDepositModalOpen = (farm) => {
    setSelectedFarmDeposit(farm);
    setIsDepositModalActive(true);
  }

  const [selectedNft, setSelectedNft] = useState(null);

  const [isNftPreviewModalActive, setIsNftPreviewModalActive] = useState(false);
  const handleNftPreviewModalClose = () => setIsNftPreviewModalActive(false);
  const handleNftPreviewModalOpen = (nft) => {
    setSelectedNft(nft);
    setIsNftPreviewModalActive(true);
  }

  const [selectedFarmDepositNft, setSelectedFarmDepositNft] = useState(null);
  const [isDepositNftModalActive, setIsDepositNftModalActive] = useState(false);

  const handleDepositNftModalClose = () => {
    setIsDepositNftModalActive(false);
    setSelectedFarmDepositNft(null);
  }

  const handleDepositNftModalOpen = (farm) => {
    setSelectedFarmDepositNft(farm);
    setIsDepositNftModalActive(true);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const syncFarms = async () => {
      setFarmState(prevState => ({ ...prevState, loading: true }));
      const farmsResult = await fetchFarms();
      setFarmState(prevState => ({ ...prevState, ...farmsResult, loading: false }));
    }

    const intervalId = setInterval(syncFarms, 15000);

    syncFarms();

    return () => clearInterval(intervalId);
  }, [setFarmState]);

  const renderFarms = () => {
    if (farmState.firstLoad && farmState.loading) {
      return (
        <div className="has-text-centered">
          <span className="icon-text is-align-items-center">
            <span className="icon is-large">
              <FontAwesomeIcon icon={ faCog } spin size="2x" />
            </span>
            <span>Loading...</span>
          </span>
        </div>
      );
    }

    const filteredFarms = farmState.farms.filter(farm => {
      let show = farm.isTokenOnly === isTokenOnly;
      if (stakedOnly) {
        show = show && new BigNumber(farm.stakedBalance || 0).gt(0);
      }
      if (activeOnly) {
        show = show && new BigNumber(farm.allocPoint || 0).gt(0);
      } else {
        show = show && new BigNumber(farm.allocPoint || 0).eq(0);
      }
      if (search !== '' && show) {
        show = farm.name.toLowerCase().includes(search.toLowerCase());
      }

      return show;
    });

    let sortedFarms = filteredFarms;
    if (sort === 'multiplier') {
      sortedFarms = sortedFarms.sort((a, b) => (new BigNumber(b.allocPoint).gt(a.allocPoint) ? 1 : -1));
    }
    if (sort === 'apr') {
      sortedFarms = sortedFarms.sort((a, b) => (new BigNumber(b.apr).gt(a.apr) ? 1 : -1));
    }
    if (sort === 'earned') {
      sortedFarms = sortedFarms.sort((a, b) => {
        const aDecimals = a.isTokenOnly ? a.tokenDecimals : process.env.REACT_APP_DECIMALS;
        const aValue = new BigNumber(a.pendingToken).div(new BigNumber(10).pow(aDecimals));
        const bDecimals = a.isTokenOnly ? a.tokenDecimals : process.env.REACT_APP_DECIMALS;
        const bValue = new BigNumber(a.pendingToken).div(new BigNumber(10).pow(bDecimals));

        return aValue.gt(bValue) ? -1 : 1;
      });
    }

    if (isListView) {
      return (
        <div className="box p-0 border">
          { sortedFarms.map((farm, i) => <FarmRow key={ farm.pid } farmData={ farm } handleRoiCalculatorModalOpen={ handleRoiCalculatorModalOpen } handleWithdrawModalOpen={ handleWithdrawModalOpen } handleDepositModalOpen={ handleDepositModalOpen } nativeTokenPrice={ farmState.nativeTokenPrice } nativeTokenPriceDefault={ farmState.nativeTokenPriceDefault } pendingUsd={ farmState.pendingUsd } pendingExtraBanksy={ farmState.pendingExtraBanksy } handleNftPreviewModalOpen={ handleNftPreviewModalOpen } handleDepositNftModalOpen={ handleDepositNftModalOpen } lastItem={ filteredFarms.length === i + 1 } />) }
        </div>
      );
    }

    return (
      <div className="columns is-centered is-multiline">
        { sortedFarms.map(farm => <FarmCard key={ farm.pid } farmData={ farm } handleRoiCalculatorModalOpen={ handleRoiCalculatorModalOpen } handleWithdrawModalOpen={ handleWithdrawModalOpen } handleDepositModalOpen={ handleDepositModalOpen } nativeTokenPrice={ farmState.nativeTokenPrice } nativeTokenPriceDefault={ farmState.nativeTokenPriceDefault } pendingUsd={ farmState.pendingUsd } pendingExtraBanksy={ farmState.pendingExtraBanksy } handleNftPreviewModalOpen={ handleNftPreviewModalOpen } handleDepositNftModalOpen={ handleDepositNftModalOpen } />) }
      </div>
    );
  }

  return (
    <>
      <div className="parallax" style={{ backgroundImage: `url("/images/parallax/bg-${isTokenOnly ? 1 : 2}.png")` }} />
      <EarnNav />
      <header className="hero is-relative">
        <div className="parallax-mobile" style={{ backgroundImage: `url("/images/parallax/bg-${isTokenOnly ? 1 : 2}.png")` }} />
        <div className="hero-body">
          <div className="container">
            <div className="hero-box has-text-centered">
              <p className="title">3D Smart Farming</p>
              <p className="subtitle">
                Stake{isTokenOnly ? '' : ' LP'} Tokens and earn <span className="has-text-primary">{ nativeTokenName }</span>.
                {isTokenOnly ? (
                  <><br />Stake <span className="has-text-primary">{ nativeTokenName }</span> Tokens and earn <span className="has-text-primary">{ stableTokenName }</span>.</>
                ) : null}
              </p>
            </div>
          </div>
        </div>
      </header>
      <main role="main" className="section has-background-white">
        <div className="container">
          <FarmFilter
            isListView={ isListView }
            setListView={ setListView }
            stakedOnly={ stakedOnly }
            setStakedOnly={ setStakedOnly }
            activeOnly={ activeOnly }
            setActiveOnly={ setActiveOnly }
            search={ search }
            setSearch={ setSearch }
            sort={ sort }
            setSort={ setSort }
            saveFilters={ saveFilters }
          />
          { renderFarms() }
        </div>
      </main>
      <div className="parallax-bottom" />

      {selectedFarmRoiCalculator ? (
        <FarmRoiCalculatorModal
          farmData={ selectedFarmRoiCalculator }
          isModalActive={ isRoiCalculatorModalActive }
          onModalClose={ handleRoiCalculatorModalClose }
          nativeTokenPrice={ farmState.nativeTokenPrice }
        />
      ) : null}
      {selectedFarmWithdraw ? (
        <FarmWithdrawModal
          farmData={ selectedFarmWithdraw }
          isModalActive={ isWithdrawModalActive }
          onModalClose={ handleWithdrawModalClose }
        />
      ) : null}
      {selectedFarmDeposit ? (
        <FarmDepositModal
          farmData={ selectedFarmDeposit }
          isModalActive={ isDepositModalActive }
          onModalClose={ handleDepositModalClose }
        />
      ) : null}
      {selectedFarmDepositNft ? (
        <FarmDepositNftModal
          farmData={ selectedFarmDepositNft }
          isModalActive={ isDepositNftModalActive }
          onModalClose={ handleDepositNftModalClose }
        />
      ) : null}
      {selectedNft ? (
        <NftPreviewModal
          nftData={ selectedNft }
          isNftPreviewModalActive={ isNftPreviewModalActive }
          onNftPreviewModalClose={ handleNftPreviewModalClose }
        />
      ) : null}
    </>
  );
}

export default FarmsPage;
