import React, { useState, useEffect } from 'react';
import BigNumber from 'bignumber.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import NftNav from './components/NftNav';
import NftPreviewModal from './components/NftPreviewModal';

import { fetchNftRanking } from '../../blockchain/nft';
import { nftName } from '../../utils/nft';

const NftRankingPage = () => {
  const [nftRankingState, setNftRankingState] = useState({
    loading: false,
    firstLoad: true,
    items: [],
  });
  const [selected, setSelected] = useState(0);
  const [isNftPreviewModalActive, setIsNftPreviewModalActive] = useState(false);

  const handleNftPreviewModalClose = () => setIsNftPreviewModalActive(false);
  const handleNftPreviewModalOpen = (evt, index) => {
    evt.preventDefault();
    setSelected(index);
    setIsNftPreviewModalActive(true);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const syncNftRanking = async () => {
      setNftRankingState(prevState => ({ ...prevState, loading: true }));
      const rs = await fetchNftRanking();
      setNftRankingState(prevState => ({ ...prevState, ...rs, loading: false, firstLoad: false }));
    }

    const intervalId = setInterval(syncNftRanking, 30000);

    syncNftRanking();

    return () => clearInterval(intervalId);
  }, [setNftRankingState]);

  return (
    <>
      <div className="parallax" style={{ backgroundImage: 'url("/images/parallax/bg-2.png")' }} />
      <NftNav />
      <header className="hero is-relative">
      <div className="parallax-mobile" style={{ backgroundImage: 'url("/images/parallax/bg-2.png")' }} />
        <div className="hero-body">
          <div className="container">
            <div className="hero-box has-text-centered">
              <p className="title">NFT Ranking</p>
              <p className="subtitle">Top User's Experienced <span className="has-text-primary">NFT ArtWork</span> Ranking.</p>
            </div>
          </div>
        </div>
      </header>
      <main role="main" className="section has-background-white">
        <div className="container">
          {nftRankingState.firstLoad && nftRankingState.loading ? (
            <div className="has-text-centered">
              <span className="icon-text is-align-items-center">
                <span className="icon is-large">
                  <FontAwesomeIcon icon={ faCog } spin size="2x" />
                </span>
                <span>Loading...</span>
              </span>
            </div>
          ) : (
            <div className="card p-0">
              <table className="table is-fullwidth is-striped is-hoverable">
                <thead>
                  <tr>
                    <th className="has-text-right"><abbr title="Position">Pos</abbr></th>
                    <th className="has-text-centered">Name</th>
                    <th className="has-text-centered is-hidden-mobile"><abbr title="Generation">Generation</abbr></th>
                    <th className="has-text-centered"><abbr title="Power">Power</abbr></th>
                    <th className="has-text-centered"><abbr title="Experience">Experience</abbr></th>
                  </tr>
                </thead>
                <tbody>
                  {nftRankingState.items.map((nft, i) => (
                    <tr key={ nftName(nft) }>
                      <td className="has-text-right">{ i + 1 }</td>
                      <td className="has-text-centered">
                        <a href="/" onClick={ (evt) => handleNftPreviewModalOpen(evt, i) }>{ nftName(nft) }</a>
                      </td>
                      <td className="has-text-centered is-hidden-mobile">{ nft.generation }</td>
                      <td className="has-text-centered">{ nft.power }</td>
                      <td className="has-text-centered">
                        <strong className="has-text-primary">{ new BigNumber(nft.experience).toFormat(0) }</strong>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </main>
      <div className="parallax-bottom" />

      <NftPreviewModal
        nftData={ nftRankingState.items[selected] }
        isNftPreviewModalActive={ isNftPreviewModalActive }
        onNftPreviewModalClose={ handleNftPreviewModalClose }
      />
    </>
  );
}

export default NftRankingPage;
