import React, { useEffect, useState } from 'react';

import HomeNav from '../home/components/HomeNav';

import Add from './components/Add';
import Remove from './components/Remove';

const LiquidityToolBoxPage = () => {
  const [mode, setMode] = useState('add');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChangeMode = (evt, newMode) => {
    evt.preventDefault();

    if (mode !== newMode) {
      setMode(newMode);
    }
  }

  return (
    <>
      <div className="parallax" style={{ backgroundImage: 'url("/images/parallax/bg-0.png")' }} />
      <HomeNav />
      <header className="hero is-relative">
        <div className="parallax-mobile" style={{ backgroundImage: 'url("/images/parallax/bg-0.png")' }} />
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="hero-box">
              <p className="title is-size-1">Banksy Toolbox</p>
              <p className="subtitle is-size-3">Add/Remove LPs, LIQUIDITY <span className="has-text-primary">TAX FREE</span> HERE!</p>
            </div>
          </div>
        </div>
      </header>
      <main role="main" className="section has-background-white">
        <div className="container">
          <div className="columns is-justify-content-center">
            <div className="column is-half">
              <div className="box p-0">
                <div className="tabs is-centered">
                  <ul>
                    <li className={ mode === 'add' ? 'is-active' : null }>
                      <a
                        href="/"
                        onClick={ evt => handleChangeMode(evt, 'add') }
                      >
                        Add
                      </a>
                    </li>
                    <li className={ mode === 'remove' ? 'is-active' : null }>
                      <a
                        href="/"
                        onClick={ evt => handleChangeMode(evt, 'remove') }
                      >
                        Remove
                      </a>
                    </li>
                  </ul>
                </div>
                <Add isActive={ mode === 'add' } />
                <Remove isActive={ mode === 'remove' } />
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className="parallax-bottom" />
    </>
  );
}

export default LiquidityToolBoxPage;
