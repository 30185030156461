import React from 'react';
import BigNumber from 'bignumber.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const VaultFilter = ({ totalBuyBackAmount, stakedOnly, setStakedOnly, search, setSearch, saveFilters }) => {
  const onToggleStakedOnly = () => {
    setStakedOnly(prevState => !prevState);
    saveFilters({ stakedOnly: !stakedOnly });
  }

  const onSearchChange = (evt) => {
    const { target } = evt;
    setSearch(target.value);
    saveFilters({ search: target.value });
  }

  const totalBurned = () => {
    const tb = new BigNumber(totalBuyBackAmount)
      .div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS));

    return tb.toFormat(tb.gt(1) ? 2 : 12);
  }

  return (
    <div className="columns is-mobile is-multiline is-justify-content-center-desktop is-align-items-center">
      <div className="column is-half-mobile is-narrow-desktop">
        <div className="field">
          <input id="stakedOnly" type="checkbox" name="stakedOnly" className="switch is-rounded is-outlined" checked={ stakedOnly } onChange={ onToggleStakedOnly } />
          <label htmlFor="stakedOnly">Staked Only</label>
        </div>
      </div>
      <div className="column is-half-mobile is-narrow-desktop">
        <div className="field">
          <p className="control has-icons-left">
            <input className="input is-rounded" type="text" placeholder="Search..." value={ search } onChange={ onSearchChange } />
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={ faSearch } />
            </span>
          </p>
        </div>
      </div>
      <div className="column has-text-right-desktop">
        Total Burned: <strong className="has-text-danger">{ totalBurned() }</strong>
      </div>
    </div>
  );
}

export default VaultFilter;
