import React, { useContext, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';

import { addLiquidity, approveLiquidityToolBox, fetchLiquidityToolBoxAdd } from '../../../blockchain/liquidityToolBox';
import { ToastContext } from '../../../context/toast';
import { getAddress, nativeTokenName, networkTokenName, stableTokenName } from '../../../utils/commons';

const names = {
  [process.env.REACT_APP_NATIVE_TOKEN]: nativeTokenName,
  [process.env.REACT_APP_NETWORK_TOKEN]: networkTokenName,
  [process.env.REACT_APP_STABLE_TOKEN]: stableTokenName,
};

const Add = ({ isActive }) => {
  const { addToast } = useContext(ToastContext);
  const token = process.env.REACT_APP_NATIVE_TOKEN;
  const [quote, setQuote] = useState(process.env.REACT_APP_NETWORK_TOKEN);
  const [tokenValue, setTokenValue] = useState('');
  const [quoteValue, setQuoteValue] = useState('');
  const [pendingTx, setPendingTx] = useState(false);

  const [addState, setLiquidityToolBoxState] = useState({
    loading: false,
    firstLoad: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const syncLiquidityToolBox = async () => {
      setLiquidityToolBoxState(prevState => ({ ...prevState, loading: true }));
      const rs = await fetchLiquidityToolBoxAdd(token, quote);
      setLiquidityToolBoxState(prevState => ({ ...prevState, ...rs, loading: false, firstLoad: false }));
    }

    const intervalId = setInterval(syncLiquidityToolBox, 5000);

    syncLiquidityToolBox();

    return () => clearInterval(intervalId);
  }, [setLiquidityToolBoxState, token, quote]);

  const handleApprove = async (address) => {
    setPendingTx(true);
    let tx;
    try {
      tx = await approveLiquidityToolBox(getAddress(address));
      await tx.wait();
      addToast((
        <>
          Token Approve succeeded <a href={ `https://ftmscan.com/tx/${tx.hash}` } target="_blank" rel="noreferrer">see more</a>.
        </>
      ), 'is-success');
    } catch (error) {
      tx = { error: error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('Token Approve failed', 'is-danger');
    }

    setPendingTx(false);
  }

  const handleSupply = async () => {
    setPendingTx(true);
    let tx;
    try {
      const quoteVal = new BigNumber(quoteValue).times(new BigNumber(10).pow(addState.quoteDecimals)).toFixed();
      const tokenVal = new BigNumber(tokenValue).times(new BigNumber(10).pow(addState.tokenDecimals)).toFixed();
      tx = await addLiquidity(getAddress(quote), quoteVal, tokenVal);
      await tx.wait();
      setTokenValue('');
      setQuoteValue('');
      addToast((
        <>
          LP supply success <a href={ `https://ftmscan.com/tx/${tx.hash}` } target="_blank" rel="noreferrer">see more</a>.
        </>
      ), 'is-success');
    } catch (error) {
      tx = { error: error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('Transaction failed', 'is-danger');
    }

    setPendingTx(false);
  }

  const handleTokenChange = (evt) => {
    const { target } = evt;
    setTokenValue(target.value);
    setQuoteValue(new BigNumber(target.value).times(addState.priceQuoteVsToken).toFixed(6));
  }

  const handleQuoteChange = (evt) => {
    const { target } = evt;
    setQuoteValue(target.value);
    setTokenValue(new BigNumber(target.value).times(addState.priceTokenVsQuote).toFixed(6));
  }

  const handleQuoteSelect = (evt) => {
    const { target } = evt;
    setQuote(target.value);
    setTokenValue('');
    setQuoteValue('');
  }

  const setTokenMax = (mul) => {
    const quoteVal = new BigNumber(addState.userTokenBalance).times(mul);

    setTokenValue(quoteVal.toFixed(6));
    setQuoteValue(quoteVal.times(addState.priceQuoteVsToken).toFixed(6));
  }

  const setQuoteMax = (mul) => {
    const quoteVal = new BigNumber(addState.userQuoteBalance).times(mul);

    setQuoteValue(quoteVal.toFixed(6));
    setTokenValue(quoteVal.times(addState.priceTokenVsQuote).toFixed(6));
  }

  const renderAction = () => {
    if (new BigNumber(addState.userTokenAllowance).eq(0)) {
      return (
        <button
          onClick={ () => handleApprove(token) }
          type="button"
          disabled={ pendingTx }
          className={ `button is-primary is-fullwidth ${pendingTx ? 'is-loading' : ''}` }
        >
          Approve { names[token] }
        </button>
      );
    }

    if (new BigNumber(addState.userQuoteAllowance).eq(0)) {
      return (
        <button
          onClick={ () => handleApprove(quote) }
          type="button"
          disabled={ pendingTx }
          className={ `button is-primary is-fullwidth ${pendingTx ? 'is-loading' : ''}` }
        >
          Approve { names[quote] }
        </button>
      );
    }

    if (new BigNumber(tokenValue).gt(addState.userTokenBalance)) {
      return (
        <button disabled className="button is-primary is-fullwidth">
          { names[token] } insufficient balance.
        </button>
      );
    }

    if (new BigNumber(quoteValue).gt(addState.userQuoteBalance)) {
      return (
        <button disabled className="button is-primary is-fullwidth">
          { names[quote] } insufficient balance.
        </button>
      );
    }

    return (
      <button
        onClick={ handleSupply }
        disabled={ pendingTx || new BigNumber(tokenValue || 0).eq(0) }
        type="button"
        className={ `button is-primary is-fullwidth ${pendingTx ? 'is-loading' : ''}` }
      >
        Supply
      </button>
    );
  }

  return (
    <div className={ `px-3 pb-3 ${ isActive ? '' : 'is-hidden' }` }>
      <div className="buttons is-right mb-0">
        <button className="button is-success is-small is-outlined" onClick={ () => setTokenMax(0.25) }>
          25%
        </button>
        <button className="button is-success is-small is-outlined" onClick={ () => setTokenMax(0.5) }>
          50%
        </button>
        <button className="button is-success is-small is-outlined" onClick={ () => setTokenMax(0.75) }>
          75%
        </button>
        <button className="button is-success is-small is-outlined" onClick={ () => setTokenMax(1) }>
          100%
        </button>
        <button
          onClick={ () => setTokenMax(1) }
          type="button"
          className="button is-text"
        >
          { new BigNumber(addState.userTokenBalance).toFormat(6) } available
        </button>
      </div>
      <div className="field has-addons">
        <p className="control">
          <button type="button" className="button is-static">{ names[token] }</button>
        </p>
        <p className="control is-expanded">
          <input value={ tokenValue } onChange={ handleTokenChange } className="input has-text-right" type="text" placeholder="0" />
        </p>
      </div>
      <div className="buttons is-right mb-0">
        <button className="button is-success is-small is-outlined" onClick={ () => setQuoteMax(0.25) }>
          25%
        </button>
        <button className="button is-success is-small is-outlined" onClick={ () => setQuoteMax(0.5) }>
          50%
        </button>
        <button className="button is-success is-small is-outlined" onClick={ () => setQuoteMax(0.75) }>
          75%
        </button>
        <button className="button is-success is-small is-outlined" onClick={ () => setQuoteMax(1) }>
          100%
        </button>
        <button
          onClick={ () => setQuoteMax(1) }
          type="button"
          className="button is-text"
        >
          { new BigNumber(addState.userQuoteBalance).toFormat(6) } available
        </button>
      </div>
      <div className="field has-addons mb-5">
        <p className="control">
          <span className="select">
            <select value={ quote } onChange={ handleQuoteSelect }>
              <option value={ process.env.REACT_APP_NETWORK_TOKEN }>{ networkTokenName }</option>
              <option value={ process.env.REACT_APP_STABLE_TOKEN }>{ stableTokenName }</option>
            </select>
          </span>
        </p>
        <p className="control is-expanded">
          <input value={ quoteValue } onChange={ handleQuoteChange } className="input has-text-right" type="text" placeholder="0" />
        </p>
      </div>
      <div className="content">
        <ul>
          <li>1 { names[token] }: <strong>{ new BigNumber(addState.priceTokenVsQuote).toFormat(6) } { names[quote] }</strong></li>
          <li>1 { names[quote] }: <strong>{ new BigNumber(addState.priceQuoteVsToken).toFormat(6) } { names[token] }</strong></li>
        </ul>
      </div>
      { renderAction() }
    </div>
  );
}

export default Add;
