import React, { useContext } from 'react';

import Toast from './components/Toast';

import { ToastContext } from '../../../context/toast';

const ToastList = () => {
  const { list } = useContext(ToastContext);

  return (
    <div style={{ position: 'fixed', bottom: '3rem', right: '1rem', width: '24rem', zIndex: 40, }}>
      {list.map(toast => <Toast key={ toast.uid } toastData={ toast } />)}
    </div>
  );
}

export default ToastList;
