const contractsMainNet = new Map();
const contractsTestNet = new Map();

// internal
contractsMainNet.set('masterChef', '0x3921A03fB2280aa6D4c68443169270835a14938f');
contractsMainNet.set('treasuryDAO', '0x6B05FD9a16d93EF7466a818254e438f465b3fcc6');
contractsMainNet.set('treasuryUtil', '0x9261E3888C9a718B436DC5fAe61bd33b261171Db');
contractsMainNet.set('liquidityToolBox', '0xDB81380e24521524933fC747FC2BC6D31864B7a9');
contractsMainNet.set('multiCall', '0xa637f776832a01d1b99DEb4B2Ee34eB5662A3FF5');

// contractsMainNet.set('vaultChef', '0x293520e3127A824dfc378B94c3E55538A761C53C'); // FIXME

contractsMainNet.set('nftChef', '0x9C26e24ac6f0EA783fF9CA2bf81543c67cf446d2');
// contractsMainNet.set('nftChef', '0x82D86C9e898033D6c0a4d729Bfcd2b5C5c91f0E0'); // TEST
contractsMainNet.set('nftSale0', '0xfC53ECb2722CCA10f63D271c1a7e94C1Bb2d5447');
contractsMainNet.set('nftSale1', '0x51273D4bEf87E194AfA311c965e7ea77762f6F93');
contractsMainNet.set('nftSale2', '0x0484C2d96800DC12756279D24FbCF03e2E4b033C');
contractsMainNet.set('nftAirdrop', '0xB6b24C88a3Ca3581fAe9ab6e093F69C6b5Fda41f');
contractsMainNet.set('nftMerge', '0x146dFbAa92d995e5dFb64Ab88cCC343A7F07d8A7');

contractsMainNet.set('preBanksy', '0xDC6C72BAac72cd1F22CB3E43F8379B21b1b9Ec1e');
contractsMainNet.set('preBanksySwap', '0xDDE217F596564036b65935b0CA0493977f568CcA');
// single tokens
contractsMainNet.set('banksy', '0x23Cf4F93F81f9332Eb0B66FEe8ED04b2DAc46ff9');
contractsMainNet.set('ftm', '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83');
contractsMainNet.set('eth', '0x74b23882a30290451A17c44f4F05243b6b58C76d');
contractsMainNet.set('btc', '0x321162Cd933E2Be498Cd2267a90534A804051b11');
contractsMainNet.set('dai', '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E');
contractsMainNet.set('usdc', '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75');
contractsMainNet.set('usdt', '0x049d68029688eAbF473097a2fC38ef61633A3C7A');
contractsMainNet.set('banksyv1', '0x17230A02f23722f5e2afb0fB1F359d6905c7a678');
// LPs
contractsMainNet.set('banksy-ftm', '0x539d72966bf632A9A4cE4C6C2b7a1835fd39362C');
contractsMainNet.set('banksy-usdc', '0x00D6508e6B041E77499EA6D908291bfcAAd42d9C');
contractsMainNet.set('ftm-usdc', '0x2b4C76d0dc16BE1C31D4C1DC53bF9B45987Fc75c');
contractsMainNet.set('dai-ftm', '0xe120ffBDA0d14f3Bb6d6053E90E63c572A66a428');
contractsMainNet.set('usdt-ftm', '0x5965E53aa80a0bcF1CD6dbDd72e6A9b2AA047410');
contractsMainNet.set('eth-ftm', '0xf0702249F4D3A25cD3DED7859a165693685Ab577');
contractsMainNet.set('btc-ftm', '0xFdb9Ab8B9513Ad9E419Cf19530feE49d412C3Ee3');
contractsMainNet.set('usdt-usdc', '0xFdef392aDc84607135C24ca45DE5452d77aa10DE');
contractsMainNet.set('banksyv1-usdc', '0xe7bb84cFadb535969D692E237916684804446b66');

// Fake
contractsMainNet.set('preBanksy-ftm', '0x539d72966bf632A9A4cE4C6C2b7a1835fd39362C'); // for pool#1
// contractsMainNet.set('fdai-ftm', '0xe120ffbda0d14f3bb6d6053e90e63c572a66a428');
// contractsMainNet.set('fdai', '0x3573E1C88D66073F1d5d497a12EB7eb796a244CE');
// contractsMainNet.set('fusdc', '0x3064EDEE4ADA222806db307b90A0b7A36B4cbd18');
// contractsMainNet.set('fbanksy', '0xdB1d67c070D4D4aE74E70A4EB790E3b643E9a30c');
// contractsMainNet.set('fbanksy-ftm', '0xa9fd3FBb9af4736ea62994f3618a23b1a9e3dbE3');
// contractsMainNet.set('fbanksy-usdt', '0x990F27a2944aB70Dca1bCF0334Cb7ED28e91081a');

export { contractsMainNet, contractsTestNet };
