import React from 'react';
import BigNumber from 'bignumber.js';
import { nativeTokenName } from '../../../utils/commons';

const TokenStatsSection = ({ farmState }) => {
  const decimals = new BigNumber(10).pow(process.env.REACT_APP_DECIMALS);

  const tokenTotalSupply = new BigNumber(farmState.tokenTotalSupply).div(decimals);
  const tokenTotalBurned = new BigNumber(farmState.tokenTotalBurned).div(decimals);
  const tokenMaximumSupply = new BigNumber(farmState.tokenMaximumSupply).div(decimals);
  const tokenPerSecond = new BigNumber(farmState.tokenPerSecond).div(decimals);
  const marketCap = new BigNumber(farmState.nativeTokenPrice).times(tokenTotalSupply);
  const circulatingSupply = tokenTotalSupply.minus(tokenTotalBurned);

  return (
    <div className="box">
      <h3 className="subtitle has-text-centered-mobile has-text-left-dekstop">{ nativeTokenName } Stats</h3>
      <nav className="columns is-multiline is-mobile is-align-items-flex-end">
        <div className="column is-half-mobile is-one-quarter-desktop has-text-centered">
          <div>
            <p className="heading">MarketCap</p>
            <p className="title is-4">${ marketCap.toFormat(0) }</p>
          </div>
        </div>
        <div className="column is-half-mobile is-one-quarter-desktop has-text-centered">
          <div>
            <p className="heading">Total Minted</p>
            <p className="title is-4">{ tokenTotalSupply.toFormat(0) }</p>
          </div>
        </div>
        <div className="column is-half-mobile is-one-quarter-desktop has-text-centered">
          <div>
            <p className="heading">Circulating Supply</p>
            <p className="title is-4">{ circulatingSupply.toFormat(0) }</p>
          </div>
        </div>
        <div className="column is-half-mobile is-one-quarter-desktop has-text-centered">
          <div>
            <p className="heading">Max Supply</p>
            <p className="title is-4">{ tokenMaximumSupply.toFormat(0) }</p>
          </div>
        </div>
        <div className="column is-half-mobile is-one-quarter-desktop has-text-centered">
          <div>
            <p className="heading">{ nativeTokenName }/Seconds</p>
            <p className="title is-4">{ tokenPerSecond.toFormat(2) }</p>
          </div>
        </div>
        {/* <div className="column is-half-mobile is-one-quarter-desktop has-text-centered">
          <div>
            <p className="heading">Provided Liquidity</p>
            <p className="title is-4 has-text-primary">12,292</p>
          </div>
        </div> */}

        <div className="column is-half-mobile is-one-quarter-desktop has-text-centered">
          <div>
            <p className="heading">Timelock</p>
            <p className="title is-4 has-text-primary">24hs</p>
          </div>
        </div>
        <div className="column is-half-mobile is-one-quarter-desktop has-text-centered">
          <div>
            <p className="heading">ANTI-BOT</p>
            {farmState.maxUserTransferAmountRate === 10000 ? (
              <p className="title is-4 has-text-danger">OFF</p>
            ) : (
              <p className="title is-4 has-text-success">ON</p>
            )}
          </div>
        </div>
        <div className="column is-half-mobile is-one-quarter-desktop has-text-centered">
          <div>
            <p className="heading">TOKEN FEES</p>
            <p className="title is-4 has-text-danger">3.33%</p>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default TokenStatsSection;
