import React, { useState, useEffect } from 'react';
import Countdown from 'react-countdown';
import BigNumber from 'bignumber.js';
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import VaultDepositSection from '../VaultDepositSection';
import VaultWithdrawSection from '../VaultWithdrawSection';
import VaultRowDetailsSection from './components/VaultRowDetailsSection';

import './VaultRow.css';

const VaultRow = ({ vaultData, lastItem = false }) => {
  const [showForms, setShowForms] = useState(false)
  const [started, setStarted] = useState(false);

  useEffect(() => {
    const updateTimes = () => {
      const currentTime = Date.now() / 1000;
      const ss = new BigNumber(process.env.REACT_APP_LAUNCH_AT);

      setStarted(prevState => prevState || (ss.gt(0) && ss.lt(currentTime)));
    }

    const intervalId = setInterval(updateTimes, 1000);

    updateTimes();

    return () => clearInterval(intervalId);
  }, [setStarted]);

  const vaultImageSrc = () => `/images/farms/${vaultData.name}.png`.replace(' LP', '').toLowerCase();

  const staked = () => {
    const decimals = process.env.REACT_APP_DECIMALS;

    const swt = new BigNumber(vaultData.stakedWantTokens)
      .div(new BigNumber(10).pow(decimals))

    return swt.toFormat(swt.gt(1) ? 6 : 12);
  }

  const toggleShowForms = () => setShowForms(prevState => !prevState)

  const sanitizeValue = (val) => {
    const newValue = new BigNumber(val).times(100);
    return newValue.gt(new BigNumber(10).pow(10)) ? 'INSANE' : newValue.toFormat(2);
  }

  return (
    <div className={ lastItem ? '' : 'has-border-bottom' }>
      <div className="p-3">
        <div className="columns is-multiline is-mobile is-align-items-center">
          <div className="column is-one-thirds-mobile is-narrow">
            <figure className="image is-48x48">
              <img src={ vaultImageSrc() } alt={ vaultData.name } />
            </figure>
          </div>
          <div className="column is-two-thirds-mobile">
            <h5 className="title is-5 mb-0">{ vaultData.name }</h5>
            <span className="tag is-rounded is-primary">{ vaultData.from }</span>
          </div>
          <div className="column is-half-mobile is-2-desktop">
            <label>Staked</label>
            <h6 className="has-text-weight-semibold">{ staked() }</h6>
          </div>
          <div className="column is-half-mobile is-2-desktop">
            <label>TVL</label>
            <h6 className="has-text-weight-semibold">${ new BigNumber(vaultData.totalValue).toFormat(2) }</h6>
          </div>
          <div className="column is-half-mobile is-2-desktop">
            <label>
              APY
            </label>
            <h6 className="has-text-weight-semibold has-text-primary">
              { sanitizeValue(vaultData.apy) }%
            </h6>
          </div>
          <div className="column is-half-mobile is-2-desktop">
            <div className="is-flex is-align-items-center">
              <div>
                <label>Daily</label>
                <h6 className="has-text-weight-semibold has-text-warning">
                  { sanitizeValue(vaultData.roi) }%
                </h6>
              </div>
              <button className="button is-ghost ml-auto" onClick={ toggleShowForms }>
                <FontAwesomeIcon icon={ showForms ? faAngleUp : faAngleDown } />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={ `p-3 ${showForms ? null : 'is-hidden'}` }>
        <div className="columns">
          <div className="column is-order-2-desktop is-flex">
            <div className="box is-flex-grow-1 is-flex-direction-column is-flex is-justify-content-center is-align-items-center">
              <p>Auto-Compound { vaultData.name }</p>
              <p className="is-size-3 has-text-primary">{ staked() }</p>
            </div>
          </div>
          <div className="column is-order-1-desktop">
            <VaultDepositSection vaultData={ vaultData } started={ started } />
          </div>
          <div className="column is-order-3-desktop">
            <VaultWithdrawSection vaultData={ vaultData } />
          </div>
        </div>
        {started ? (
          <VaultRowDetailsSection vaultData={ vaultData } />
        ) : (
          <p className="has-text-centered is-size-5 has-text-warning">
            <span className="mr-1">Deposit starts at</span><Countdown date={ Number(process.env.REACT_APP_LAUNCH_AT) * 1000 } />
          </p>
        )}
      </div>
    </div>
  );
}

export default VaultRow;
