import React from 'react';
import BigNumber from 'bignumber.js';
import { faSync, faTractor } from '@fortawesome/free-solid-svg-icons';
import { faFortAwesome } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUpNumber from '../../../layout/components/CountUpNumber';

const TotalValueLockedSection = ({ farmState, poolState, vaultState }) => {
  const farmsTvl = new BigNumber(farmState.tvl);
  const farmsTnl = new BigNumber(farmState.tnl);
  const poolsTvl = new BigNumber(poolState.tvl);
  const vaultsTvl = new BigNumber(vaultState.tvl);
  const tvl = farmsTvl.plus(poolsTvl);
  const tnl = farmsTnl.plus(poolsTvl);

  return (
    <div className="box">
      <div className="columns">
        <div className="column">
          <p className="subtitle">Total Value Locked</p>
          <p className="title is-2"><small className="is-size-3">$</small><CountUpNumber value={ farmState.firstLoad ? 0 : tvl.toNumber() } decimals={ 0 } /></p>
        </div>
        <div className="column">
          <p className="subtitle">Total Native Locked</p>
          <p className="title is-2"><small className="is-size-3">$</small><CountUpNumber value={ farmState.firstLoad ? 0 : tnl.toNumber() } decimals={ 0 } /></p>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="icon-text">
            <span className="icon is-large has-text-info">
              <FontAwesomeIcon icon={ faTractor } size="2x" />
            </span>
            <div className="is-flex is-flex-direction-column">
              <p className="title is-4">
                $<CountUpNumber value={ farmsTvl.toNumber() } decimals={ 0 } />
              </p>
              <p className="subtitle is-6 has-text-info">Farms</p>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="icon-text">
            <span className="icon is-large has-text-success">
              <FontAwesomeIcon icon={ faFortAwesome } size="2x" />
            </span>
            <div className="is-flex is-flex-direction-column">
              <p className="title is-4">${ poolsTvl.toFormat(0) }</p>
              <p className="subtitle is-6 has-text-success">Walls</p>
            </div>
          </div>
        </div>
        {/* <div className="column">
          <div className="icon-text">
            <span className="icon is-large has-text-warning">
              <FontAwesomeIcon icon={ faSync } size="2x" />
            </span>
            <div className="is-flex is-flex-direction-column">
              <p className="title is-4">${ vaultsTvl.toFormat(0) }</p>
              <p className="subtitle is-6 has-text-warning">Vaults</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default TotalValueLockedSection;
