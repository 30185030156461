import React, { useEffect, useContext } from 'react';

import { ToastContext } from '../../../../context/toast';

const Toast = ({ toastData }) => {
  const { deleteToast } = useContext(ToastContext);

  useEffect(() => {
    const interval = setInterval(() => {
      deleteToast(toastData.uid);
    }, 5000);

    return () => clearInterval(interval);
  });

  const handleClose = () => {
    deleteToast(toastData.uid);
  }

  return (
    <div className={ `notification mb-3 ${toastData.kind}` }>
      <button onClick={ handleClose } type="button" className="delete" />
      { toastData.text }
    </div>
  );
}

export default Toast;
