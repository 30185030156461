import React, { createContext, useState } from 'react';

import poolInitialState from '../state/poolInitialState';

export const PoolsContext = createContext();

export const PoolsProvider = (props) => {
  const [poolState, setPoolState] = useState(poolInitialState);

  return (
    <PoolsContext.Provider value={{ poolState, setPoolState }}>
      { props.children }
    </PoolsContext.Provider>
  );
}
