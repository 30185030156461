import React, { useContext, useRef, useState } from 'react';
import { WalletContext } from '../../../../context/wallet';
import { disconnect } from '../../../../blockchain/commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCopy } from '@fortawesome/free-solid-svg-icons';

const AccountModal = ({ isModalActive, onModalClose }) => {
  const { walletAddress } = useContext(WalletContext);
  const [success, setSuccess] = useState(false);

  const input = useRef(null);

  const handleCopyToClipboard = (evt) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(walletAddress).then(() => {
        setSuccess(true);
      });
    } else {
      input.select();
      document.execCommand('copy');
      evt.target.focus();
      setSuccess(true);
    }
  }

  const handleDisconnect = () => {
    disconnect();
    onModalClose();
  }

  return (
    <div className={`modal ${isModalActive ? 'is-active' : null}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Your Wallet </p>
          <button className="delete" aria-label="close" onClick={ onModalClose }></button>
        </header>
        <section className="modal-card-body">
          <div className="field has-addons">
            <div className="control is-expanded">
              <input ref={ input } readOnly className="input" type="text" placeholder="Your address" value={ walletAddress || '' } />
            </div>
            <div className="control">
              <button type="button" className="button" onClick={ handleCopyToClipboard }>
                <FontAwesomeIcon icon={ success ? faCheck : faCopy } />
              </button>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot is-justify-content-flex-end">
          <button
            className="button is-success"
            onClick={ handleDisconnect }
          >
            Logout
          </button>
          <button className="button" onClick={ onModalClose }>Close</button>
        </footer>
      </div>
    </div>
  );
}

export default AccountModal;
