import React from 'react';

import NftCard from './NftCard';

const NftPreviewModal = ({ nftData, isNftPreviewModalActive, onNftPreviewModalClose }) => {
  return (
    <div className={`modal ${isNftPreviewModalActive ? 'is-active' : null}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">NFT Preview</p>
          <button className="delete" aria-label="close" onClick={ onNftPreviewModalClose }></button>
        </header>
        <section className="modal-card-body">
          { nftData ? (
            <NftCard nftData={ nftData } />
          ) : null }
        </section>
        <footer className="modal-card-foot is-justify-content-flex-end">
          <button
            className="button"
            onClick={ onNftPreviewModalClose }
          >
            Close
          </button>
        </footer>
      </div>
    </div>
  );
}

export default NftPreviewModal;
