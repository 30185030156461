import React, { useRef, useEffect } from 'react';
import CountUp from 'react-countup';

const CountUpNumber = ({ value, decimals = 3 }) => {
  const previousValue = useRef(0);

  useEffect(() => {
    if (!isNaN(value))
      previousValue.current = value;
  }, [value]);

  return (
    <>
      {isNaN(value) ? null : (<CountUp
        start={ previousValue.current }
        end={ value }
        decimals={ decimals }
        duration={ 1 }
        separator=","
      />)}
    </>
  );
}

export default CountUpNumber;
