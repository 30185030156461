import BigNumber from 'bignumber.js';
import multicall from '../blockchain/multicall';
import erc20Abi from '../config/abis/erc20.json';
import { getAddress, getLpAddress } from '../utils/commons';

export const fetchNativeTokenPrice = async () => {
  const tokenAddress = getAddress(process.env.REACT_APP_NATIVE_TOKEN);
  const quoteTokenAddress = getAddress(process.env.REACT_APP_STABLE_TOKEN);
  const lpAddress = getLpAddress(process.env.REACT_APP_NATIVE_TOKEN, process.env.REACT_APP_STABLE_TOKEN);

  const erc20Calls = [
    {
      address: tokenAddress,
      name: 'balanceOf',
      params: [lpAddress],
    },
    {
      address: quoteTokenAddress,
      name: 'balanceOf',
      params: [lpAddress],
    },
    {
      address: tokenAddress,
      name: 'decimals',
    },
    {
      address: quoteTokenAddress,
      name: 'decimals',
    },
  ];

  const [
    tokenBalanceLP,
    quoteTokenBalanceLP,
    tokenDecimals,
    quoteTokenDecimals,
  ] = await multicall(erc20Abi, erc20Calls);

  if (new BigNumber(quoteTokenBalanceLP).eq(0) || new BigNumber(tokenBalanceLP).eq(0)) {
    return {
      amount: new BigNumber(process.env.REACT_APP_DEFAULT_PRICE).toNumber(),
      default: true,
    };
  }

  return {
    amount: new BigNumber(quoteTokenBalanceLP)
      .div(new BigNumber(tokenBalanceLP))
      .times(new BigNumber(10).pow(tokenDecimals - quoteTokenDecimals))
      .toNumber(),
    default: false,
  };
};
