import React, { useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import NftNav from './components/NftNav';
import NftCard from './components/NftCard';
import NftInfo from './components/NftInfo';

import { NftContext } from '../../context/nft';
import { fetchMyNfts } from '../../blockchain/nft';

const NftHomePage = () => {
  const { nftState, setNftState } = useContext(NftContext);

  useEffect(() => {
    const syncMyNfts = async () => {
      setNftState(prevState => ({ ...prevState, loading: true }));
      const nftResult = await fetchMyNfts();
      setNftState(prevState => ({ ...prevState, ...nftResult, loading: false }));
    }

    const intervalId = setInterval(syncMyNfts, 10000);

    syncMyNfts();

    return () => clearInterval(intervalId);
  }, [setNftState]);

  const isLoading = () => nftState.firstLoad && nftState.loading;

  const renderResults = () => {
    if (isLoading()) {
      return (
        <span className="icon-text is-align-items-center">
          <span className="icon is-large">
            <FontAwesomeIcon icon={ faCog } spin size="2x" />
          </span>
          <span>Loading...</span>
        </span>
      );
    }

    if (nftState.myNfts.length > 0) {
      return nftState.myNfts.map(nftData => (
        <div key={ `nft-${nftData.generation}-${nftData.pid}` } className="column is-half-desktop">
          <NftCard nftData={ nftData } />
        </div>
      ));
    }

    return (
      <NftCard
        nftData={{
          pid: 2110,
          experience: 287,
          generation: 1,
          power: 27,
          boostStake: 12.18,
        }}
        ribbon="ON SALE"
        ribbonLink="/nft-sale"
      />
    );
  }

  return (
    <>
      <div className="parallax" style={{ backgroundImage: 'url("/images/parallax/bg-0.png")' }} />
      <NftNav />
      <header className="hero is-relative">
        <div className="parallax-mobile" style={{ backgroundImage: 'url("/images/parallax/bg-0.png")' }} />
        <div className="hero-body">
          <div className="container">
            <div className="hero-box has-text-centered">
              <p className="title">My NFTs ArtWorks</p>
              <p className="subtitle"><span className="has-text-primary">NFT's ArtWorks</span> power will be unique and generated randomly.</p>
            </div>
          </div>
        </div>
      </header>
      <main role="main" className="section has-background-white">
        <div className="container">
          <div className="columns is-multiline is-justify-content-center">
            { renderResults() }
          </div>
          <div className="columns is-justify-content-center">
            <div className="column is-half">
              <NftInfo />
            </div>
          </div>
        </div>
      </main>
      <div className="parallax-bottom" />
    </>
  );
}

export default NftHomePage;
