import React from 'react';
import BigNumber from 'bignumber.js';

const PoolHarvestNftSection = ({ poolData }) => {
  const experienceEarned = () => {
    if (!poolData.userHasNFT) {
      return 0.0;
    }

    const earnedValue = new BigNumber(poolData.userPendingReward).times(poolData.experienceRate).div(10000).div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS));

    return earnedValue.toFormat(earnedValue.eq(0) || earnedValue.gt(1) ? 2 : 6);
  }

  const earned = () => {
    if (!poolData.userHasNFT) {
      return 0.0;
    }

    const rawEarnedValue = new BigNumber(poolData.userPendingReward)
      .div(new BigNumber(10).pow(poolData.rewardTokenDecimals));

    const earnedValue = rawEarnedValue.times(poolData.selectedNft?.boostStake || 0).div(100);

    return earnedValue.toFormat(earnedValue.eq(0) || earnedValue.gt(1) ? 2 : 6);
  }

  const boost = () => {
    if (!poolData.userHasNFT) {
      return null;
    }

    return `(${new BigNumber(poolData.selectedNft.boostStake).toFormat(2)}%)`;
  }

  return (
    <>
      <small className="is-flex is-justify-content-space-between">
        <span className="has-text-warning">BOOST { boost() }</span>
        <span className="has-text-warning">EXPERIENCE</span>
      </small>
      <div className="level is-mobile">
        <div className="level-left">
          <div className="level-item">
            <h5 className="is-size-5 has-text-weight-semibold">
              { earned() }
            </h5>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <h5 className="is-size-5 has-text-weight-semibold">
              { experienceEarned() }
            </h5>
          </div>
        </div>
      </div>
    </>
  );
}

export default PoolHarvestNftSection;
