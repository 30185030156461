import React, { createContext, useState } from 'react';

import vaultInitialState from '../state/vaultInitialState';

export const VaultsContext = createContext();

export const VaultsProvider = (props) => {
  const [vaultState, setVaultState] = useState(vaultInitialState);

  return (
    <VaultsContext.Provider value={{ vaultState, setVaultState }}>
      { props.children }
    </VaultsContext.Provider>
  );
}
