import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const HomeNav = () => {
  const location = useLocation();

  return (
    <nav className="section has-background-light pt-3 pb-0 has-border-bottom is-hidden-mobile">
      <div className="container">
        <div className="tabs mb-npx">
          <ul>
            <li className={ location.pathname === '/dashboard' ? 'is-active' : '' }>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className={ location.pathname === '/timeline' ? 'is-active' : '' }>
              <Link to="/timeline">Timeline</Link>
            </li>
            <li className={ location.pathname === '/toolbox' ? 'is-active' : '' }>
              <Link to="/toolbox">Liquidity Toolbox</Link>
            </li>
            <li className={ location.pathname === '/anti-bot' ? 'is-active' : '' }>
              <Link to="/anti-bot">Anti-bot</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default HomeNav;
