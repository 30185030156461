import { Interface } from '@ethersproject/abi';
import multicallAbi from '../config/abis/Multicall.json';
import { getContract } from '../blockchain/commons';

const multicall = async (abi, calls) => {
  const itf = new Interface(abi);
  const multicallContract = getContract('multiCall', multicallAbi);
  const calldata = calls.map((call) => [
    call.address.toLowerCase(),
    itf.encodeFunctionData(call.name, call.params || [])
  ]);
  const { returnData } = await multicallContract.aggregate(calldata);

  return returnData.map((call, i) => itf.decodeFunctionResult(calls[i].name, call))
}

export default multicall;
