import React, { createContext, useState } from 'react';

import nftInitialState from '../state/nftInitialState';

export const NftContext = createContext();

export const NftProvider = (props) => {
  const [nftState, setNftState] = useState(nftInitialState);

  return (
    <NftContext.Provider value={{ nftState, setNftState }}>
      { props.children }
    </NftContext.Provider>
  );
}
