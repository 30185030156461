import React from 'react';
import BigNumber from 'bignumber.js';
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAddress, getLpAddress, swapAddress } from '../../../../../utils/commons';

const VaultRowDetailsSection = ({ vaultData }) => {
  const linkToViewOn = () => {
    let token;
    if (vaultData.isTokenOnly) {
      token = getAddress(vaultData.token);
    } else {
      token = getLpAddress(vaultData.token, vaultData.quoteToken);
    }

    return `${process.env.REACT_APP_EXPLORER}/token/${token}`;
  }

  return (
    <div className="columns is-justify-content-space-between is-align-items-center">
      <div className="column is-narrow">
        <a className="button is-ghost" href={ swapAddress(vaultData) } target="_blank" rel="noreferrer">
          <span className="icon-text">
            Get { vaultData.name }
            <span className="icon ml-1">
              <FontAwesomeIcon icon={ faExternalLinkAlt } />
            </span>
          </span>
        </a><br />
        <a href={ linkToViewOn() } className="button is-ghost" target="_blank" rel="noreferrer">
          <span className="icon-test">
            Open Token
            <span className="icon ml-1">
              <FontAwesomeIcon icon={ faExternalLinkAlt } />
            </span>
          </span>
        </a>
      </div>
      <div className="column is-narrow has-text-right-desktop">
        <a href={ `${process.env.REACT_APP_EXPLORER}/address/${vaultData.strategy}` } className="button is-ghost" target="_blank" rel="noreferrer">
          <span className="icon-test">
            Open Strategy
            <span className="icon ml-1">
              <FontAwesomeIcon icon={ faExternalLinkAlt } />
            </span>
          </span>
        </a>
        <p className="ml-4">
          Auto Burned: <span className="has-text-danger">{ new BigNumber(vaultData.buyBackAmount).div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS)).toFormat(12) }</span>
        </p>
      </div>
    </div>
  );
}

export default VaultRowDetailsSection;
