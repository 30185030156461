import React, { createContext, useState, useEffect } from 'react';
import { getWalletAddress, attachWalletListeners } from '../blockchain/commons';

import WalletModal from '../shared/WalletModal';

export const WalletContext = createContext();

export const WalletProvider = (props) => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [showWalletModal, setShowWalletModal] = useState(false);

  useEffect(() => {
    attachWalletListeners(setWalletAddress);
    const assignWallet = async () => setWalletAddress(await getWalletAddress());
    const intervalId = setInterval(assignWallet, 20000);
    assignWallet();
    return () => clearInterval(intervalId);
  }, []);

  const handleConnectClick = () => {
    if (walletAddress === null) {
      setShowWalletModal(true);
    }
  }

  const handleWalletModalClose = (walletAddress = null) => {
    if(walletAddress !== null){
      setWalletAddress(walletAddress);
    }
    setShowWalletModal(false);
  }

  return (
    <WalletContext.Provider value={{ walletAddress, handleConnectClick }}>
      { props.children }

      <WalletModal isModalActive={ showWalletModal } onModalClose={ handleWalletModalClose } />
    </WalletContext.Provider>
  );
}
